<template>
  <div>
    <div class="section-box section-box--fullblack section-box--no-top-padding">
      <div class="wrapper">
        <v-contacts></v-contacts>
      </div>
    </div>
  </div>
</template>

<script>
import ContactsBlock from '@/components/layout/Contacts/Contacts-block'
export default {
  name: "Contacts",
  metaInfo: {
    title: 'ITernal Group – IT vendor for complex software solutions'
  },
  components: {
    'v-contacts': ContactsBlock
  }
}
</script>

<style lang="scss">

</style>
