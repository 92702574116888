<template>
  <div>
    <h1 class="section-title section-title--white-big" v-html="title"></h1>
    <div class="contacts">
      <div class="contact-country" v-for="contact in contacts" :key="contact.title">
        <p class="contact-country__name">
          <component :is="'v-icon-' + contact.icon" class="contact-country__name-img"></component>
          {{ contact.title }}
        </p>
        <div class="contact-country-item-list">
          <div class="contact-country-item" v-for="item in contact.list" :key="item.name">
            <img :src="require('@/assets/images/' + item.image)" v-if="item.image" :alt="item.name" class="contact-country-item__img">
            <p class="contact-country-item__title">{{ item.name }}</p>
            <p class="contact-country-item__line">
              <a :href="'mailto:' + item.email" class="contact-country-item__link">{{ item.email }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts-block",
  data() {
    return {
      title: '<span class="section-title__highlighted section-title__highlighted--white">Get in touch</span> to make your software a reality',
      contacts: [
        {
          title: 'Ukraine, Kyiv',
          icon: 'ua',
          list: [
            {
              image: 'bondarchuk.jpg',
              name: 'Vitalii Bondarchuk',
              email: 'vitalii.bondarchuk@iternal.group'
            }
          ]
        },
        {
          title: 'USA, Texas, Austin',
          icon: 'usa',
          list: [
            {
              image: 'hamman.jpg',
              name: 'Buckley Hamman',
              phoneShort: '+17373336385',
              email: 'buckley.hamman@iternal.group',
            }
          ]
        }
      ]
    }
  },
  components: {
    'v-icon-ua': () => import('@/assets/icons/ua.svg'),
    'v-icon-usa': () => import('@/assets/icons/usa.svg'),
  }
}
</script>

<style lang="scss">
.contacts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;

  @media (max-width: $viewport-mobile) {
    margin-top: 50px;
  }
}

.contact-country {
  margin-right: 200px;
  margin-bottom: 50px;

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.contact-country__name {
  display: flex;
  margin-bottom: 30px;
  font-family: Visuelt Pro;
  color: #fff;
  font-size: 1rem;
}

.contact-country__name-img {
  margin-top: 1px;
  margin-right: 15px;
  width: 30px;
  height: 20px;
}

.contact-country-item-list {
  display: flex;
  flex-wrap: wrap;
}

.contact-country-item {
  margin-right: 85px;
  margin-bottom: 50px;

  &:last-child {
    margin-right: 0;

    @media (max-width: $viewport-mobile) {
      margin-bottom: 0;
    }
  }

  @media (max-width: $viewport-tablet-small) {
    margin-bottom: 35px;
  }
}

.contact-country-item__img {
  display: block;
  margin-bottom: 30px;
  max-width: 226px;
}

.contact-country-item__time {
  margin-bottom: 10px;
  color: #fff;
  font-size: 0.75rem;
}

.contact-country-item__title {
  margin-bottom: 5px;
  font-family: Visuelt Pro;
  color: #fff;
  font-size: 2.125rem;
}

.contact-country-item__line {
  margin-bottom: 7px;
}

.contact-country-item__link {
  font-size: 0.875rem;
  color: #838383;
  border-bottom: 1px solid rgba(131,131,131,.5);
  transition: border-bottom-color .2s ease-in-out;

  &:hover {
    border-bottom-color: transparent;
  }
}
</style>
